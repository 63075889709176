import { useCallback, useEffect, useState } from 'react';

export default function useSecondsCountDown() {
  const [count, setCount] = useState(-1);
  const [isRunning, setIsRunning] = useState(false);

  const startCountDown = useCallback(seconds => {
    setCount(seconds);
    setIsRunning(true);
  }, []);

  const stopCountDown = useCallback(() => setIsRunning(false), []);

  useEffect(() => {
    let timerId;

    if (isRunning && count > 0) {
      timerId = window.setTimeout(() => setCount(count - 1), 1000);
    }

    return () => window.clearTimeout(timerId);
  }, [count, isRunning]);

  return [count, startCountDown, stopCountDown];
}
