import React from 'react';
import './AppBuildInfo.css';

const AppBuildInfo = () => {
  if (!window.APP_VERSION) {
    return null;
  }

  return (
    <footer className="AppBuildInfo__root">
      <span className="AppBuildInfo__label">{window.APP_VERSION}</span>
    </footer>
  );
};

export default AppBuildInfo;
