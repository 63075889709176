import React, { useState, useEffect } from "react";
import { setPhoneNumber } from "../api/api";
import Button from "../components/Button";
import history from "../history";
import { useLocation } from "react-router-dom";
import CommonPage from "./CommonPage";
import links from "src/links";
import "./style.css";

const telValidationError = "Phone number should not be less than 7 characters";

const SetPhonePage = () => {
  const [tel, setTel] = useState("");
  const [isTelInvalid, setTelValidation] = useState(false);
  const location = useLocation();

  const setUserTel = async () => {
    const sliceFrom = location.search.indexOf("token=");
    const token = location.search.slice(sliceFrom + 6);
    const result = await setPhoneNumber(tel, token);
    if (result.msg === "Phone number is added") {
      history.push(links.login);
    }
  };

  const onTelChange = (e) => setTel(e.target.value);

  useEffect(() => {
    if (tel.length < 7) {
      setTelValidation(true);
      return;
    }
    setTelValidation(false);
  }, [tel]);

  return (
    <CommonPage>
      <div className="reset-pass-form-input-shell">
        <input
          className="reset-pass-form-input"
          type="tel"
          pattern="[0-9]{14}"
          value={tel}
          onChange={onTelChange}
        />
      </div>
      <div className="first-pass-field-error">
        {isTelInvalid ? telValidationError : null}
      </div>
      <div className="reset-password-footer">
        <Button disabled={isTelInvalid} onClick={setUserTel}>
          Submit
        </Button>
      </div>
    </CommonPage>
  );
};

export default SetPhonePage;
