import React from 'react';
import logo from '../../assets/images/lock-up-black.png';
import './style.css';

const CommonPage = ({ children, otherElems }) => (
  <div className="page">
    <div className="form">
      <div className="logo-shell">
        <img className="logo" src={logo} alt="Dustin logo" />
      </div>
      <div className="form-body">{children}</div>
    </div>
    {otherElems ? otherElems : null}
  </div>
);

export default CommonPage;
