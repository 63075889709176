import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import links from 'src/links';
import { checkAuthRequest } from 'src/store/thunks';
import ResetPassForm from 'src/pages/ResetPassForm';
import RememberDevice from 'src/pages/RememberDevice';
import AuthVerifyPage from 'src/pages/AuthVerifyPage';
import ForgotPassword from 'src/pages/ForgotPassword';
import SetPhonePage from 'src/pages/SetPhonePage';
import LoginPage from 'src/pages/LoginPage';

const OldRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuthRequest());
  }, []);

  return (
    <Switch>
      <Route path={`${links.resetPassForm}*`} component={ResetPassForm} />
      <Route path={links.rememberDevice} component={RememberDevice} />
      <Route path={links.authVerify} component={AuthVerifyPage} />
      <Route path={links.forgotPassword} component={ForgotPassword} />
      <Route path={`${links.setPhone}*`} component={SetPhonePage} />
      <Route path={links.login} component={LoginPage} />
      <Route path="*" component={LoginPage} />
    </Switch>
  );
};

export default OldRouter;
