import { FROM_URL } from 'src/constants/queryParams';

export const getReferrerPage = () =>
  window.location.href !== document.referrer ||
  (document.referrer && !document.referrer.startsWith(window.location.href))
    ? document.referrer
    : '';

export const getDefaultRedirectLink = () => `${window.SKYPORTAL_URL}/framework`;
/**
 * @param {string} comeFrom
 */
export const saveInStoreFromUrl = comeFrom => localStorage.setItem(FROM_URL, comeFrom);
export const getStoredFromUrl = () => localStorage.getItem(FROM_URL);
export const removeStoredFromUrl = () => localStorage.removeItem(FROM_URL);

export const redirectToPrevHost = () => {
  const storedFromUrl = getStoredFromUrl();

  removeStoredFromUrl();

  window.location.href = storedFromUrl || getDefaultRedirectLink();
};
