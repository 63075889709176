import React, { useState } from 'react';
import LoginEmailInput from '../components/LoginEmailInput';
import PasswordEmailInput from '../components/LoginPasswordInput';
import LoginFormFooter from '../components/LoginFormFooter';
import CommonPage from './CommonPage';
import { useDispatch, useSelector } from 'react-redux';
import { appStateFunc } from 'src/store/selectors';
import { signIn } from 'src/api/api';
import { useHistory } from 'react-router-dom';
import links from 'src/links';
import { INVALID_CREDS } from 'src/store/types';
import AppBuildInfo from 'src/components/AppBuildInfo';
import { redirectToPrevHost } from 'src/utils/redirect';

import './style.css';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { email, password } = useSelector(appStateFunc);
  const [isPhoneRequired, setPhoneRequired] = useState(false);
  const [enterPressed, setEnterPressed] = useState(false);

  const handleFormKeyPress = event => {
    if (event.key === 'Enter') {
      setEnterPressed(true);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const result = await signIn({ email, password });
    const responseMsg = result.msg;

    switch (responseMsg) {
      case 'Code must be verified': {
        history.push(links.authVerify);
        return;
      }
      case 'Successfully logged': {
        redirectToPrevHost();
        return;
      }
      case 'Invalid username or password': {
        dispatch({ type: INVALID_CREDS });
        return;
      }
      case 'Phone number required': {
        setPhoneRequired(true);
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <CommonPage>
      <p className="form-header-text">Sign in to start your session</p>
      <form noValidate onSubmit={handleSubmit} onKeyPress={handleFormKeyPress}>
        <LoginEmailInput />
        <PasswordEmailInput />
        {isPhoneRequired && (
          <div className="field-error">
            <p className="field-error-text">Phone number required. Please check your email</p>
          </div>
        )}
        <LoginFormFooter enterPressed={enterPressed} />
      </form>
      <AppBuildInfo />
    </CommonPage>
  );
};

export default LoginPage;
