import URLSearchParams from '@ungap/url-search-params';

/**
 * @param {string} key
 * @param {string} search - query param string without "?" prefix
 * @return {string}
 */
export const customQueryParamParser = (key, search) => {
  try {
    return (
      search
        .split('&')
        .map(item => item.split('='))
        .find(([searchKey]) => searchKey === key)[1] || ''
    );
  } catch (_) {
    return '';
  }
};

/**
 * @param {string} key
 * @return {string}
 */
export const getQueryParam = key => {
  const search = window.location.search.slice(1);
  try {
    const params = new URLSearchParams(search);
    return params.get(key) || '';
  } catch (_) {
    return customQueryParamParser(key, search);
  }
};
