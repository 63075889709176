import React from 'react';
import classnames from 'classnames';
import './field-styles.css';

const Button = ({ children, disabled, ...rest }) => {
  return (
    <button
      className={classnames('login-footer-btn', {
        'sign-in-disabled': disabled,
      })}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
