import React from 'react';
import { acceptSaveDeviceCookie } from 'src/store/thunks';
import { useDispatch } from 'react-redux';

import './field-styles.css';

const RememberDeviceForm = () => {
  const dispatch = useDispatch();

  const handleYesBtnClick = () => dispatch(acceptSaveDeviceCookie(true));
  const handleNoBtnClick = () => dispatch(acceptSaveDeviceCookie(false));

  return (
    <div className="save-device-shell">
      <h2 className="save-device-header">Remember device?</h2>
      <p className="save-device-text">You can choose this to avoid device verification for every login</p>
      <div className="save-device-footer">
        <button className="login-footer-btn" onClick={handleNoBtnClick}>
          No
        </button>
        <button className="login-footer-btn" onClick={handleYesBtnClick}>
          Yes
        </button>
      </div>
    </div>
  );
};

export default RememberDeviceForm;
