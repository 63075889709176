import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import app from './appReducer';

const ext = window.__REDUX_DEVTOOLS_EXTENSION__;
const devtoolMiddleware = ext && process.env.NODE_ENV === 'development' ? ext() : f => f;

/**
 * @typedef {object} Store
 * @property {AppState} app
 */

const store = createStore(combineReducers({ app }), compose(applyMiddleware(thunk), devtoolMiddleware));

export default store;
