export const isCodeCorrectFunc = state => state.app.validation.isCodeCorrect;
/**
 * @param {Store} state
 * @return {AppState}
 */
export const appStateFunc = state => state.app;
/**
 * @param {Store} state
 * @return {boolean}
 */
export const isCredsValidFunc = state => appStateFunc(state).validation.isCredentialsValid;
/**
 * @param {Store} state
 * @return {boolean}
 */
export const resetPassNotificationFunc = state => appStateFunc(state).validation.resetPassNotification;
/**
 * @param {Store} state
 * @return {LoginMode}
 */
export const getLoginMode = state => appStateFunc(state).loginMode;
/**
 * @param {Store} state
 * @return {number|null}
 */
export const getOktaAuthErrorCode = state => appStateFunc(state).oktaAuthErrorCode;
/**
 * @param {Store} state
 * @return {RequestStatus}
 */
export const getOktaTokenRequestStatus = state => appStateFunc(state).oktaTokenRequestStatus;
