import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
  clientId: window.OKTA_CLIENT_ID,
  issuer: `https://${window.OKTA_DOMAIN}/oauth2/default`,
  redirectUri: window.location.origin + '/implicit/callback',
  devMode: process.env.NODE_ENV === 'development',
});

export default oktaAuth;
