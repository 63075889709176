import { NETWORK_ERROR } from 'src/constants/apiErrors';

/**
 * @typedef {object} ApiResultMessage
 * @property {string} msg
 */

/**
 * @param {object} response
 * @return {ApiResultMessage}
 */
export const getSuccessMessageFromAxios = response => {
  let msg = '';

  try {
    msg = response.data.msg || '';
  } catch (_) {}

  return { msg };
};

/**
 * @param {object} error
 * @return {ApiResultMessage}
 */
export const getErrorMessageFromAxios = error => {
  let msg = NETWORK_ERROR;

  try {
    msg = error.response.data.msg || '';
  } catch (_) {}

  return { msg };
};

/**
 * @param {object} error
 * @return {number|null}
 */
export const getErrorCodeFromAxios = error => {
  try {
    const { code } = error.response.data;
    return typeof code === 'number' ? code : null;
  } catch {
    return null;
  }
};
