import { STRONG_PASSWORD_LENGTH, STRONGER_PASSWORD_LENGTH, WEEK_PASSWORD_LENGTH } from './constants';

/**
 * @param {string} password
 * @return {number} - password strong score (0 | 1 | 2 | 3)
 */
export const getPasswordScore = password => {
  let score = 0;

  if (password.length >= WEEK_PASSWORD_LENGTH) score += 1;
  if (password.length >= STRONG_PASSWORD_LENGTH) score += 1;
  if (password.length >= STRONGER_PASSWORD_LENGTH) score += 1;

  return score;
};
