import React from 'react';
import LoginEmailInput from '../components/LoginEmailInput';
import { useSelector, useDispatch } from 'react-redux';
import CommonPage from './CommonPage';
import Button from '../components/Button';
import { resetPassNotificationFunc, appStateFunc } from 'src/store/selectors';
import history from '../history';
import links from 'src/links';
import { forgotPasswordRequest } from 'src/store/thunks';

import './style.css';

const ForgotPassword = () => {
  const { email, errorMsg } = useSelector(appStateFunc);
  const dispatch = useDispatch();
  const resetPassNotification = useSelector(resetPassNotificationFunc);

  const onResetPassword = async () => {
    await dispatch(forgotPasswordRequest(email));
    history.push(links.login);
  };

  return (
    <CommonPage>
      <p className="form-header-text">Enter email to reset password</p>
      <LoginEmailInput />
      {resetPassNotification && (
        <div className="field-error">
          <p className="field-error-text">{errorMsg}</p>
        </div>
      )}
      <div className="reset-password-footer">
        <Button onClick={onResetPassword}>Submit</Button>
      </div>
    </CommonPage>
  );
};

export default ForgotPassword;
