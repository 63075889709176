import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import links from 'src/links';

const useLogoutAction = () => {
  const history = useHistory();

  return useCallback(() => history.push({ pathname: links.logout }), [history]);
};

export default useLogoutAction;
