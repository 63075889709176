import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INVALID_TOKEN, NETWORK_ERROR } from 'src/constants/apiErrors';
import { PASSWORD_UPDATED } from 'src/constants/apiMessages';
import { getQueryParam } from 'src/utils/url';
import { RESET_PASSWORD_TOKEN } from 'src/constants/queryParams';
import PasswordStrengthBar from 'src/components/PasswordStrengthBar';
import CommonPage from './CommonPage';
import { resetPassword } from '../api/api';
import Button from '../components/Button';
import links from '../links';

import './style.css';

const PASSWORD_MIN_LENGTH = 12;

const ResetPassForm = () => {
  const location = useLocation();
  const history = useHistory();
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [firstFieldTouched, setFirstFieldTouched] = useState(false);
  const [firstFieldError, setFirstFieldError] = useState(false);
  const [secondFieldError, setSecondFieldError] = useState(false);
  const token = useMemo(() => getQueryParam(RESET_PASSWORD_TOKEN), [location]);
  const isSubmitAllowed = Boolean(!firstFieldError && !secondFieldError && password && repeatPass && token);

  const onPasswordChange = event => setPassword(event.target.value);
  const onRepeatPasswordChange = event => setRepeatPass(event.target.value);

  useEffect(() => {
    if (!firstFieldTouched) {
      return;
    }

    setFirstFieldError(!password || password.length < PASSWORD_MIN_LENGTH);
  }, [password, firstFieldTouched]);

  useEffect(() => {
    setApiErrorMessage('');

    if (!password || !repeatPass) {
      return;
    }

    setSecondFieldError(password !== repeatPass);
  }, [password, repeatPass]);

  const handleResponseMessage = message => {
    switch (message) {
      case PASSWORD_UPDATED:
        history.push(links.login);
        break;
      case INVALID_TOKEN:
        setApiErrorMessage('Invalid token.');
        break;
      case NETWORK_ERROR:
        setApiErrorMessage('Something wrong with network.');
        break;
      default:
        setApiErrorMessage('Something went wrong.');
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!isSubmitAllowed) {
      return;
    }

    const response = await resetPassword(password, token);
    handleResponseMessage(response.msg);
  };

  return (
    <CommonPage>
      <div className="form-header-text">Reset Password</div>
      <form onSubmit={handleSubmit} noValidate>
        <div className="reset-pass-form-input-shell">
          <input
            onBlur={() => setFirstFieldTouched(true)}
            onChange={onPasswordChange}
            className="reset-pass-form-input"
            type="password"
            placeholder="Password"
          />
          <span className="reset-pass-form-icon">
            <span className="glyphicon glyphicon-lock" />
          </span>
        </div>
        {firstFieldError && <div className="first-pass-field-error">Minimum {PASSWORD_MIN_LENGTH} characters.</div>}
        <div className="reset-pass-form-input-shell">
          <input
            onChange={onRepeatPasswordChange}
            className="reset-pass-form-input"
            type="password"
            placeholder="Password"
            minLength={PASSWORD_MIN_LENGTH}
          />
          <span className="reset-pass-form-icon">
            <span className="glyphicon glyphicon-lock" />
          </span>
        </div>
        {secondFieldError && <div className="first-pass-field-error">Values in fields should be matched.</div>}
        {apiErrorMessage && <div className="first-pass-field-error">{apiErrorMessage}</div>}
        <PasswordStrengthBar password={password} />
        <div className="reset-password-footer">
          <Button type="submit" disabled={!isSubmitAllowed}>
            Submit
          </Button>
        </div>
      </form>
    </CommonPage>
  );
};

export default ResetPassForm;
