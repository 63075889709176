import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { isCredsValidFunc } from 'src/store/selectors';
import { SET_PASSWORD } from 'src/store/types';
import './field-styles.css';

const LoginPasswordInput = ({ className }) => {
  const isCredsValid = useSelector(isCredsValidFunc);
  const dispatch = useDispatch();

  const onPasswordChange = event => dispatch({ type: SET_PASSWORD, payload: event.target.value });

  return (
    <div className="field-shell">
      <input onChange={onPasswordChange} className={cn('field', className)} type="password" placeholder="Password" />
      <span className="field-icon-shell">
        <span className="glyphicon glyphicon-lock" />
      </span>
      {!isCredsValid && (
        <div className="field-error">
          <p className="field-error-text">Incorrect username or password.</p>
        </div>
      )}
    </div>
  );
};

export default LoginPasswordInput;
