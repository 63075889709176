import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import history from '../history';
import AppBuildInfo from 'src/components/AppBuildInfo';
import CommonPage from 'src/pages/CommonPage';
import Button from 'src/components/Button';
import { SplashScreen } from '@skyportal/ui-kit';

export const OktaLogout = () => {
  const [isPending, setIsPending] = useState(false);
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (window.OKTA_AUTO_REDIRECTION) {
      oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/signed-out' });
      setIsPending(true);
    }
  }, [oktaAuth]);

  const logout = () => {
    oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/signed-out' });
    setIsPending(true);
  };

  if (!window.OKTA_AUTO_REDIRECTION && !isPending) {
    return (
      <CommonPage>
        <Button onClick={logout}>Logout</Button>
        <AppBuildInfo />
      </CommonPage>
    );
  }

  return <SplashScreen />;
};

export default OktaLogout;
