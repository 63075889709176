import React from 'react';
import cn from 'classnames';
import './Spinner.css';

/**
 * @param {number} [size] - px
 * @param {string} [color] - hex/rgb(a)
 * @param {string} [position] - css position
 * @param {string} [className]
 * @return {JSX.Element}
 * @constructor
 */
export const Spinner = ({ size = 50, color = '#242424', position = 'static', className }) => {
  return (
    <div
      className={cn('Spinner', className)}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderColor: color,
        position,
        borderWidth: `${Math.floor(size / 10)}px`,
      }}
    />
  );
};

export default Spinner;
