import React, { useEffect, useState } from 'react';
import { BAR_COLORS, NO_SCORE, SCORE_WORDS, SCORES } from './constants';
import { getPasswordScore } from './utils';
import './PasswordStrengthBar.css';

/**
 * @param {string} password
 * @param {string[]} [barColors] - hexString
 * @param {string[]} [scoreWords]
 * @param {string} [tooShortWord]
 * @return {JSX.Element}
 * @constructor
 */
const PasswordStrengthBar = ({
  password,
  barColors = BAR_COLORS,
  scoreWords = SCORE_WORDS,
  tooShortWord = 'too short',
}) => {
  const [score, setScore] = useState(NO_SCORE);

  useEffect(() => {
    setScore(getPasswordScore(password));
  }, [password]);

  return (
    <div className="PasswordStrengthBar__root">
      <div className="PasswordStrengthBar__score-bar">
        {SCORES.map(item => {
          return (
            <span
              key={item}
              className="PasswordStrengthBar__score-bar-item"
              style={{ backgroundColor: score >= item ? barColors[score] : barColors[NO_SCORE] }}
            />
          );
        })}
      </div>
      <div className="PasswordStrengthBar__label-container">
        <span className="PasswordStrengthBar__label"> {score ? scoreWords[score - 1] : tooShortWord} </span>
      </div>
    </div>
  );
};

export default PasswordStrengthBar;
