import React, { useEffect, useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import useLogoutAction from 'src/hooks/useLogoutAction';
import Button from 'src/components/Button';
import { redirectToPrevHost } from 'src/utils/redirect';
import { getOktaAuthErrorCode, getOktaTokenRequestStatus } from 'src/store/selectors';
import OktaStatusText from 'src/components/OktaStatusText';
import { isNotFoundUser } from 'src/utils/okta';
import { FAILURE } from 'src/constants/state';
import DustinSupportLink from 'src/components/DustinSupportLink';
import links from 'src/links';

const OktaLoginStatus = () => {
  const [email, setEmail] = useState('');
  const tooltipRef = useRef(null);
  const logout = useLogoutAction();
  const { oktaAuth, authState } = useOktaAuth();
  const oktaTokenRequestStatus = useSelector(getOktaTokenRequestStatus);
  const code = useSelector(getOktaAuthErrorCode);

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth
        .getUser()
        .catch(console.error)
        .then(user => user.email)
        .then(setEmail);
    }
  }, [oktaAuth, authState.isAuthenticated]);

  const login = () => {
    const payload = {
      language: 'en',
      siteType: 'business',
    };
    const state = Buffer.from(JSON.stringify(payload)).toString('base64');

    oktaAuth.signInWithRedirect({ state }).catch(error => {
      console.error(error);
    });
  };

  if (isNotFoundUser(code)) {
    return (
      <OktaStatusText small>
        <p>We could not find a Skyportal account associated with the email "{email}".</p>
        <p>
          Please contact your Skyportal super-user or Dustin support <DustinSupportLink />
        </p>
        <Button onClick={logout}>Login as another user</Button>
      </OktaStatusText>
    );
  }

  if (oktaTokenRequestStatus === FAILURE) {
    return (
      <OktaStatusText small>
        <p>
          Something went wrong with authorization. Try to <a href={links.logout}>Login</a> again.
        </p>
        <p>
          <DustinSupportLink />
        </p>
      </OktaStatusText>
    );
  }

  //debug UI
  return authState.isAuthenticated ? (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={logout}>Logout</Button>
      <Button onClick={() => redirectToPrevHost()}>Go back to Skyportal</Button>
    </div>
  ) : (
    <>
      <div ref={ref => (tooltipRef.current = ref)} data-tip data-for="cookie">
        <Button onClick={login}>Login</Button>
      </div>
    </>
  );
};

export default OktaLoginStatus;
