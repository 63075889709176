import { UNCALLED, UNKNOWN_LOGIN_FLOW } from 'src/constants/state';
import oktaAuth from 'src/config/oktaAuth';
import {
  SET_EMAIL,
  SET_PASSWORD,
  INVALID_CREDS,
  SET_CODE,
  TOGGLE_WRONG_CODE_MSG,
  RESET_PASS_NOTIFICATION_SENT,
  SET_OKTA_TOKEN_REQUEST_STATUS,
  SET_LOGIN_MODE,
  SET_OKTA_AUTH_ERROR_CODE,
} from './types';

/**
 * @typedef {object} AppState
 * @property {string} email
 * @property {string} password
 * @property {string} code
 * @property {string} errorMsg
 * @property {object} validation
 * @property {boolean} validation.isCredentialsValid
 * @property {boolean} validation.isCodeCorrect
 * @property {boolean} validation.resetPassNotification
 *
 * @property {LoginMode} loginMode
 * @property {boolean} isOktaLoginRedirect
 * @property {RequestStatus} oktaTokenRequestStatus
 * @property {number|null} oktaAuthErrorCode
 * */

/**
 * @type {AppState}
 * */
export const initialState = {
  email: '',
  password: '',
  code: '',
  errorMsg: '',
  validation: {
    isCredentialsValid: true,
    isCodeCorrect: true,
    resetPassNotification: false,
  },

  loginMode: UNKNOWN_LOGIN_FLOW,
  isOktaLoginRedirect: oktaAuth.isLoginRedirect(),
  oktaTokenRequestStatus: UNCALLED,
  oktaAuthErrorCode: null,
};

export default function appReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SET_EMAIL: {
      return {
        ...state,
        email: payload,
      };
    }
    case SET_PASSWORD:
      return {
        ...state,
        password: payload,
      };
    case INVALID_CREDS: {
      return {
        ...state,
        validation: { ...state.validation, isCredentialsValid: false },
      };
    }
    case SET_CODE: {
      return {
        ...state,
        code: payload,
      };
    }
    case TOGGLE_WRONG_CODE_MSG: {
      return {
        ...state,
        validation: {
          ...state.validation,
          isCodeCorrect: !state.validation.isCodeCorrect,
        },
      };
    }
    case RESET_PASS_NOTIFICATION_SENT: {
      return {
        ...state,
        errorMsg: payload,
        validation: {
          ...state.validation,
          resetPassNotification: true,
        },
      };
    }
    case SET_OKTA_TOKEN_REQUEST_STATUS: {
      return {
        ...state,
        oktaTokenRequestStatus: action.payload,
        oktaAuthErrorCode: null,
      };
    }
    case SET_LOGIN_MODE: {
      return {
        ...state,
        loginMode: action.payload,
      };
    }
    case SET_OKTA_AUTH_ERROR_CODE: {
      return {
        ...state,
        oktaAuthErrorCode: action.payload,
      };
    }
    default:
      return state;
  }
}
