import { useEffect } from 'react';
import { getQueryParam } from 'src/utils/url';
import { FROM_URL } from 'src/constants/queryParams';
import { getReferrerPage, saveInStoreFromUrl } from 'src/utils/redirect';

const useFromUrlSave = () => {
  useEffect(() => {
    const fromUrl = getQueryParam(FROM_URL); // || getReferrerPage();
    const dustinDomains = window.DUSTIN_DOMAINS.split(',');
    const isDustinDomain = dustinDomains.some(domain => fromUrl.startsWith(domain));

    if (fromUrl && isDustinDomain) {
      saveInStoreFromUrl(fromUrl);
    }
  }, []);
};

export default useFromUrlSave;
