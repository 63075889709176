import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCodeCorrectFunc } from 'src/store/selectors';
import { setVerificationCodeAC } from 'src/store/actions';

const CodeInput = () => {
  const dispatch = useDispatch();
  const isCodeCorrect = useSelector(isCodeCorrectFunc);

  const handleChangeCode = event => dispatch(setVerificationCodeAC(event.target.value));

  return (
    <div className="field-shell">
      <input onChange={handleChangeCode} className="field" type="text" placeholder="Code" autoFocus />
      {!isCodeCorrect && (
        <div className="field-error">
          <p className="field-error-text">Email is not a valid email address.</p>
        </div>
      )}
    </div>
  );
};

export default CodeInput;
