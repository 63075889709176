import React, { useState } from "react";
import CommonPage from "./CommonPage";
import VerificationFailed from "../components/VerificationFailed";
import ContactUs from "src/components/ContactUs";
import "./style.css";
import VerificationSuccess from "src/components/VerificationSuccess";

const AuthVerifyPage = ({ history }) => {
  const [isOpenHelp, setState] = useState(false);
  const [isVerificationFailed] = useState(false);

  return (
    <CommonPage
      otherElems={
        <div className="help">
          <span className="help-link" onClick={() => setState(true)}>
            Didn't receive any code?
          </span>
          {isOpenHelp ? <ContactUs closeModal={() => setState(false)} /> : null}
        </div>
      }
    >
      {!isVerificationFailed ? <VerificationSuccess /> : <VerificationFailed />}
    </CommonPage>
  );
};

export default AuthVerifyPage;
