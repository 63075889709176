import React from 'react';
import { Button } from '@skyportal/ui-kit';
import history from '../history';
import logo from '../../assets/images/lock-up-black.png';

export const SignedOutPage = () => {
  return (
    <div className="page">
      <header className="header">
        <img className="logo-in-header" src={logo} alt="Dustin logo" />
      </header>
      <div className="signed-out">
        <h3>Signout successful!</h3>
        <p>You have been successfully signed out of Skyportal.</p>
        <Button onClick={() => history.push('/')}>Click here to sign in</Button>
      </div>
    </div>
  );
};

export default SignedOutPage;
