/**
 * @typedef {"SUCCESS"|"FAILURE"|"PENDING"|"UNCALLED"} RequestStatus
 */
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const UNCALLED = 'UNCALLED';
export const PENDING = 'PENDING';

/**
 * @typedef {"OKTA"|"OLD"|"UNKNOWN"} LoginMode
 */
export const OKTA_LOGIN_FLOW = 'OKTA';
export const OLD_LOGIN_FLOW = 'OLD';
export const UNKNOWN_LOGIN_FLOW = 'UNKNOWN';
