import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import links from '../links';

import './field-styles.css';

const LoginFormFooter = ({ enterPressed }) => {
  const tooltipRef = useRef(null);

  return (
    <div className="login-form-footer">
      <Link to={links.forgotPassword} className="forgot-password__link">
        I forgot my password
      </Link>
      <div ref={ref => (tooltipRef.current = ref)} data-tip data-for="cookie">
        <Button type="submit">Sign In</Button>
      </div>
    </div>
  );
};

export default LoginFormFooter;
