const links = {
  login: '/site/login',
  logout: '/logout',
  forgotPassword: '/site/forgot-password',
  authVerify: '/auth/verify',
  rememberDevice: '/site/remember-device',
  resetPassForm: '/v1/password',
  setPhone: '/v1/user/phone',
  dashboard: '/framework',
};

export default links;
