import React from 'react';
import { useSelector } from 'react-redux';
import { Security } from '@okta/okta-react';
import oktaAuth from 'src/config/oktaAuth';
import useFromUrlSave from 'src/hooks/useFromUrlSave';
import OktaRouter from 'src/routes/OktaRouter';
import OldRouter from 'src/routes/OldRouter';
import { OKTA_LOGIN_FLOW, OLD_LOGIN_FLOW } from 'src/constants/state';
import { getLoginMode } from 'src/store/selectors';
import useEnvAndCookieFlagsCheck from 'src/hooks/useEnvAndCookieFlagsCheck';

const EntryApp = () => {
  const loginMode = useSelector(getLoginMode);

  useEnvAndCookieFlagsCheck();
  useFromUrlSave();

  switch (loginMode) {
    case OKTA_LOGIN_FLOW:
      return (
        <Security oktaAuth={oktaAuth}>
          <OktaRouter />
        </Security>
      );
    case OLD_LOGIN_FLOW:
      return <OldRouter />;
    default:
      return null;
  }
};

export default EntryApp;
