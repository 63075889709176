import React from "react";
import RememberDeviceForm from "../components/RememberDeviceForm";
import CommonPage from "./CommonPage";
import "./style.css";

const RememberDevice = () => {
  return (
    <CommonPage>
      <RememberDeviceForm />
    </CommonPage>
  );
};

export default RememberDevice;
