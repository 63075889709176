import { acceptSaveDevice, authOktaTokenCheck, checkUser, forgotPassword } from 'src/api/api';
import { redirectToPrevHost } from 'src/utils/redirect';
import { FORGOT_PASSWORD_NOTIFICATION_SEND } from 'src/constants/apiMessages';
import { RESET_PASS_NOTIFICATION_SENT } from 'src/store/types';
import { setOktaAuthErrorCode, setOktaTokenRequestStatus } from 'src/store/actions';
import { FAILURE, PENDING, SUCCESS } from 'src/constants/state';
import { getErrorCodeFromAxios } from 'src/api/utils';

export const checkAuthRequest = () => async () => {
  const responseData = await checkUser();

  if (responseData.name) {
    redirectToPrevHost();
  }
};
/**
 * @param {string} token - Okta Id token
 */
export const checkOktaTokenRequest = token => async dispatch => {
  try {
    dispatch(setOktaTokenRequestStatus(PENDING));
    await authOktaTokenCheck(token);
    dispatch(setOktaTokenRequestStatus(SUCCESS));
  } catch (error) {
    dispatch(setOktaTokenRequestStatus(FAILURE));
    dispatch(setOktaAuthErrorCode(getErrorCodeFromAxios(error)));
  }
};

export const acceptSaveDeviceCookie = isAccepted => async () => {
  try {
    await acceptSaveDevice(isAccepted);
    redirectToPrevHost();
  } catch (error) {
    console.error(error);
  }
};

/**
 * @param {string} email
 * @return {function(*): Promise<{msg: string}>}
 */
export const forgotPasswordRequest = email => async dispatch => {
  const response = await forgotPassword(email);
  if (response.msg === FORGOT_PASSWORD_NOTIFICATION_SEND) {
    dispatch({
      type: RESET_PASS_NOTIFICATION_SENT,
      payload: '(Reset password) Notification has sent',
    });
  } else {
    dispatch({
      type: RESET_PASS_NOTIFICATION_SENT,
      payload: '(Reset password) Notification has not sent, something went wrong.',
    });
    throw response.msg;
  }
};
