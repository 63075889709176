import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import { verifyCode, resendCode } from 'src/api/api';
import { TOGGLE_WRONG_CODE_MSG } from 'src/store/types';
import CodeInput from '../components/CodeInput';
import { useSelector, useDispatch } from 'react-redux';
import history from '../history';
import { appStateFunc } from 'src/store/selectors';
import useSecondsCountDown from 'src/hooks/secondsCountDown';
import Button from '../components/Button';
import links from '../links';

const RESEND_TIMOUT_SEC = 60;

const VerificationSuccess = () => {
  const [seconds, startCountDown] = useSecondsCountDown();
  const dispatch = useDispatch();
  const { email, code } = useSelector(appStateFunc);

  const onResendCode = useCallback(async () => {
    await resendCode(email);
    startCountDown(RESEND_TIMOUT_SEC);
  }, [email]);

  useEffect(() => {
    startCountDown(RESEND_TIMOUT_SEC);
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();

    const response = await verifyCode({ email, code });
    if (response.msg === 'Successfully logged') {
      dispatch({ type: TOGGLE_WRONG_CODE_MSG });
      history.push(links.rememberDevice);
    } else if (response.msg === 'Wrong code') {
      dispatch({ type: TOGGLE_WRONG_CODE_MSG });
    }
  };

  const handleCancelBtnClick = () => history.push(links.login);

  return (
    <>
      <p className="code-form-header-text">Verify it's you</p>
      <p className="form-header-text font16">Enter your verification code in the field below</p>
      <form onSubmit={handleSubmit} noValidate>
        <CodeInput />
        <div className="auth-form-footer">
          <button disabled={seconds > 0} type="button" onClick={onResendCode} className={cn('link-like__btn')}>
            Resend Code {seconds > 0 && ` after ${seconds} seconds`}
          </button>
          <Button type="submit">Verify</Button>
        </div>
      </form>
      <div className="auth-form-subfooter">
        <button onClick={handleCancelBtnClick} className="link-like__btn">
          Cancel verification and log out
        </button>
      </div>
    </>
  );
};

export default VerificationSuccess;
