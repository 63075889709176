// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js!@skyportal/ui-kit/dist/fonts.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ":root {\n  --grey: #f0f0f0;\n  --black: #222;\n  --pale-blue: #e8f0fe;\n  --help-link-blue: #3c8dbc;\n}\n\nhtml,\nbody,\nul,\n#app {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: Dustin Sans, Sans-serif;\n  width: 100%;\n  height: 100%;\n}\n\n.page {\n  background-color: var(--grey);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n", ""]);
// Exports
module.exports = exports;
