import apiClient from './client';
import { getErrorMessageFromAxios, getSuccessMessageFromAxios } from 'src/api/utils';

/**
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.password
 * @return {Promise<ApiResultMessage>}
 */
export const signIn = data =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/auth',
      data,
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @param {string} token - Okta Id token
 */
export const authOktaTokenCheck = token =>
  apiClient.request({
    method: 'POST',
    url: '/v1/okta',
    data: { token },
  });

/**
 * @param {object} data
 * @param {string} data.email
 * @param {string} data.code
 * @return {Promise<ApiResultMessage>}
 */
export const verifyCode = data =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/verify',
      data,
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @param {boolean} remember
 * @return {Promise<ApiResultMessage>}
 */
export const acceptSaveDevice = remember =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/device/remember',
      data: { remember },
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @param {string} email
 * @return {Promise<ApiResultMessage>}
 */
export const forgotPassword = email =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/password/reset',
      data: { email },
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @param {string} email
 * @return {Promise<ApiResultMessage>}
 */
export const resendCode = email =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/repeat',
      data: { email },
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @param {string} password
 * @param {string} token
 * @return {Promise<ApiResultMessage>}
 */
export const resetPassword = (password, token) =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/password',
      params: { token },
      data: { password },
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @param {string} phone
 * @param {string} token
 * @return {Promise<ApiResultMessage>}
 */
export const setPhoneNumber = (phone, token) =>
  apiClient
    .request({
      method: 'POST',
      url: '/v1/user/phone',
      params: { token },
      data: { phone },
    })
    .then(getSuccessMessageFromAxios)
    .catch(getErrorMessageFromAxios);

/**
 * @return {Promise<object | ApiResultMessage>}
 */
export const checkUser = () =>
  apiClient
    .request({
      method: 'GET',
      url: '/v1/user/info',
    })
    .then(response => response.data)
    .catch(getErrorMessageFromAxios);
