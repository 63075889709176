export const SET_EMAIL = 'SET_EMAIL';
export const SET_PASSWORD = 'SET_PASSWORD';
export const INVALID_CREDS = 'INVALID_CREDS';
export const SET_CODE = 'SET_CODE';
export const TOGGLE_WRONG_CODE_MSG = 'TOGGLE_WRONG_CODE_MSG';
export const RESET_PASS_NOTIFICATION_SENT = 'RESET_PASS_NOTIFICATION_SENT';

export const SET_LOGIN_MODE = 'SET_LOGIN_MODE';
export const SET_OKTA_TOKEN_REQUEST_STATUS = 'OKTA/SET_OKTA_TOKEN_REQUEST_STATUS';
export const SET_OKTA_AUTH_ERROR_CODE = 'OKTA/SET_OKTA_AUTH_ERROR_CODE';
