import React from "react";

const VerificationFailed = () => (
  <div>
    <div>Verification failed</div>
    <div>
      Verification for your account failed. Please try again in a few minutes.
    </div>
    <div>
      <button>Log out</button>
    </div>
  </div>
);

export default VerificationFailed;
