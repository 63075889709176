import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { checkOktaTokenRequest } from 'src/store/thunks';

const useOktaTokenResolver = () => {
  const [idToken, setIdToken] = useState('');
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (idToken) {
      dispatch(checkOktaTokenRequest(idToken));
    }
  }, [idToken]);

  useEffect(() => {
    if (!authState.isPending) {
      oktaAuth.tokenManager.getTokens().then(tokens => {
        if (tokens.idToken && !oktaAuth.tokenManager.hasExpired(tokens.idToken)) {
          setIdToken(oktaAuth.getIdToken());
        }
      });
    }
  }, [oktaAuth, authState.isPending]);

  useEffect(() => {
    const handleRenew = (key, newToken) => {
      if (key === 'idToken') {
        setIdToken(newToken.idToken);
      }
    };
    const handleError = err => console.error('TokenManager error:', err);

    oktaAuth.tokenManager.on('renewed', handleRenew);
    oktaAuth.tokenManager.on('error', handleError);

    return () => {
      oktaAuth.tokenManager.off('renewed', handleRenew);
      oktaAuth.tokenManager.off('error', handleError);
    };
  }, [oktaAuth, dispatch]);
};

export default useOktaTokenResolver;
