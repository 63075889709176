import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { appStateFunc, getOktaTokenRequestStatus } from 'src/store/selectors';
import { SUCCESS } from 'src/constants/state';
import { redirectToPrevHost } from 'src/utils/redirect';

const useOktaCheckAuthentication = () => {
  const oktaTokenRequestStatus = useSelector(getOktaTokenRequestStatus);
  const { oktaAuth, authState } = useOktaAuth();
  const oktaHostAppLogin = !authState.isPending && !authState.isAuthenticated;
  const skyPortalWeb = oktaTokenRequestStatus === SUCCESS;

  useEffect(() => {
    if (oktaHostAppLogin && window.OKTA_AUTO_REDIRECTION) {
      const payload = {
        language: 'en',
        siteType: 'business'
      };
      const state = Buffer.from(JSON.stringify(payload)).toString('base64');

      oktaAuth.signInWithRedirect({ state }).catch(error => {
        console.error(error);
      });
    } else if (skyPortalWeb && window.OKTA_AUTO_REDIRECTION) {
      redirectToPrevHost();
    }
  }, [oktaHostAppLogin, oktaAuth, skyPortalWeb]);
};

export default useOktaCheckAuthentication;
