export const WEEK_PASSWORD_LENGTH = 12;
export const STRONG_PASSWORD_LENGTH = 16;
export const STRONGER_PASSWORD_LENGTH = 20;

export const BAR_COLORS = ['#ddd', '#ca4548', '#ffa553', '#4ef09d'];
export const SCORE_WORDS = ['weak', 'good', 'strong'];

export const NO_SCORE = 0;
export const WEEK_SCORE = 1;
export const GOOD_SCORE = 2;
export const STRONG_SCORE = 3;

export const SCORES = [WEEK_SCORE, GOOD_SCORE, STRONG_SCORE];
