import React from 'react';
import { useDispatch } from 'react-redux';
import { SET_EMAIL } from 'src/store/types';
import './field-styles.css';

const LoginEmailInput = () => {
  const dispatch = useDispatch();

  const onEmailChange = event => dispatch({ type: SET_EMAIL, payload: event.target.value });

  return (
    <div className="field-shell">
      <input onChange={onEmailChange} className="field" type="email" placeholder="Email" />
      <span className="field-icon-shell">
        <span className="glyphicon glyphicon-envelope" />
      </span>
    </div>
  );
};

export default LoginEmailInput;
