import * as Types from 'src/store/types';
import { SET_CODE, SET_LOGIN_MODE, SET_OKTA_AUTH_ERROR_CODE, SET_OKTA_TOKEN_REQUEST_STATUS } from 'src/store/types';

/**
 * @typedef {object} SetVerificationCodeAction
 * @property {"SET_CODE"} type
 * @property {string} payload
 */

/**
 * @param {string} code
 * @return {SetVerificationCodeAction}
 */
export const setVerificationCodeAC = code => ({
  type: SET_CODE,
  payload: code,
});
/**
 * @param {RequestStatus} payload
 * @return {{payload: RequestStatus, type: string}}
 */
export const setOktaTokenRequestStatus = payload => ({
  type: SET_OKTA_TOKEN_REQUEST_STATUS,
  payload,
});
/**
 * @param {number} payload
 * @return {{payload: number, type: string}}
 */
export const setOktaAuthErrorCode = payload => ({
  type: SET_OKTA_AUTH_ERROR_CODE,
  payload,
});
/**
 * @param {LoginMode} payload
 * @return {{payload: LoginMode, type: string}}
 */
export const setLoginMode = payload => ({
  type: SET_LOGIN_MODE,
  payload,
});
