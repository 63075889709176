import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { OKTA_FEATURE } from 'src/constants/cookies';
import { setLoginMode } from 'src/store/actions';
import { OKTA_LOGIN_FLOW, OLD_LOGIN_FLOW } from 'src/constants/state';

const useEnvAndCookieFlagsCheck = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies([OKTA_FEATURE]);

  useEffect(() => {
    dispatch(
      setLoginMode(window.USE_OKTA_FLOW === 'true' || Boolean(cookies[OKTA_FEATURE]) ? OKTA_LOGIN_FLOW : OLD_LOGIN_FLOW)
    );
  }, [cookies]);
};

export default useEnvAndCookieFlagsCheck;
