import React from 'react';
import cn from 'classnames';
import Spinner from 'src/components/Spinner';
import './OktaStatusText.css';

/**
 * @param {boolean} [pending]
 * @param {boolean} [small]
 * @param {React.ReactChildren} children
 * @return {JSX.Element}
 * @constructor
 */
const OktaStatusText = ({ children, pending = false, small = false }) => (
  <div className={cn('OktaStatusText', small && 'small')}>
    <h1>{children}</h1> {pending && <Spinner className="OktaStatusText__spinner" color="#fff" size={20} />}
  </div>
);

export default OktaStatusText;
