import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { Route, Switch } from 'react-router-dom';
import OktaLoginPage from 'src/pages/OktaLoginPage';
import OktaLogout from 'src/pages/OktaLogout';
import SignedOutPage from 'src/pages/SignedOutPage';

const OktaRouter = () => (
  <Switch>
    <Route path="/" exact component={OktaLoginPage} />
    <Route path="/implicit/callback" component={LoginCallback} />
    <Route path="/logout" exact component={OktaLogout} />
    <Route path="/signed-out" exact component={SignedOutPage} />
    <Route path="*" component={OktaLoginPage} />
  </Switch>
);

export default OktaRouter;
