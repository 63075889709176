import React from 'react';
import OktaLoginStatus from 'src/components/OktaLoginStatus';
import CommonPage from 'src/pages/CommonPage';
import AppBuildInfo from 'src/components/AppBuildInfo';
import useOktaTokenResolver from 'src/routes/useOktaTokenResolver';
import useOktaCheckAuthentication from 'src/routes/useOktaCheckAuthentication';
import { FAILURE, PENDING } from 'src/constants/state';
import { SplashScreen } from '@skyportal/ui-kit';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { getOktaAuthErrorCode, getOktaTokenRequestStatus } from 'src/store/selectors';
import { isNotFoundUser } from 'src/utils/okta';

const OktaLoginPage = () => {
  const { authState } = useOktaAuth();
  const code = useSelector(getOktaAuthErrorCode);
  const oktaTokenRequestStatus = useSelector(getOktaTokenRequestStatus);
  useOktaTokenResolver();
  useOktaCheckAuthentication();

  if (authState.isPending) {
    return <SplashScreen />;
  }

  if (oktaTokenRequestStatus === PENDING) {
    return <SplashScreen />;
  }

  if (isNotFoundUser(code) || oktaTokenRequestStatus === FAILURE || !window.OKTA_AUTO_REDIRECTION) {
    return (
      <CommonPage>
        <OktaLoginStatus />
        <AppBuildInfo />
      </CommonPage>
    );
  }

  return <SplashScreen />;
};

export default OktaLoginPage;
